@import 'styles/_colors.scss';
@import 'styles/_dimens.scss';

.dropdown-item {
    align-items: center;
    background: $color-background-white;
    border: none;
    border-radius: $default-border-radius;
    color: $color-text-primary;
    display: flex;
    padding: 0.5rem;
    text-align: left;

    &:hover {
        background-color: $color-neutral-9;
    }
}
