@import 'styles/_mixins.scss';
@import 'styles/_colors.scss';
@import 'styles/_dimens.scss';

$background-color: $color-background-white;

.user-avatar-container {
    align-items: center;
    display: flex;
    position: relative; /* Needed for the Dropdown to position itself */
    min-width: 14rem;

    .user-avatar-button {
        display: flex;
        align-items: center;
        padding: 0.2rem 0.5rem;
        background-color: $background-color;
        border-radius: $default-border-radius;

        @include hover-darken-background($background-color);

        &:hover {
            cursor: pointer;
        }

        .user-icon {
            width: 1.8rem;
            height: 1.8rem;
            border-radius: $default-border-radius;
            fill: $color-text-primary;
        }

        .user-name {
            padding-left: 1rem;
        }
    }
}
