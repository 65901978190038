/* Neutral Colors - Grey */

$color-neutral-1: hsl(210, 10%, 10%);
$color-neutral-2: hsl(210, 10%, 20%);
$color-neutral-3: hsl(210, 10%, 30%);
$color-neutral-4: hsl(210, 10%, 40%);
$color-neutral-5: hsl(210, 10%, 50%);
$color-neutral-6: hsl(210, 10%, 60%);
$color-neutral-7: hsl(210, 10%, 70%);
$color-neutral-8: hsl(210, 10%, 80%);
$color-neutral-9: hsl(210, 10%, 90%);
$color-neutral-10: hsl(210, 10%, 98%);

/* Semantic Colors */

$color-light: rgba(255, 255, 255, 0.9);

$color-text-primary: $color-neutral-2;
$color-text-secondary: $color-neutral-4;
$color-text-tertiary: $color-neutral-6;

$color-background-neutral: $color-neutral-4;
$color-background-dark: $color-neutral-2;
$color-background-light: $color-neutral-10;
$color-background-white: #FFFFFF;

$color-error: hsl(356, 75%, 53%);
$color-error-light: hsl(360, 100%, 87%);
$color-error-dark: hsl(348, 94%, 20%);

$color-card-header: $color-neutral-9;
