@import 'styles/_colors.scss';
@import 'styles/_dimens.scss';
@import 'styles/_mixins.scss';
@import 'styles/_buttons.scss';

$background-color: $color-background-light;

.signup {
    align-items: center;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .signup-logo {
        margin-top: 12vh;
        margin-bottom: 8vh;
        background-color: $background-color;

        @include hover-darken-background($background-color);
    }

    .signup-card {
        width: 30rem;

        .inputs-container {
            margin-top: 1rem;

            .form-input {
                width: 100%;
                margin-bottom: 2rem;
            }
        }

        .buttons-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 1rem;
            margin-bottom: 1rem;

            .signup-button {
                width: 48%;
                text-transform: capitalize;
            }

            .login-button {
                background-color: $color-light;
                width: 48%;
                text-transform: capitalize;

                @include border-button($color-text-primary, $color-light);
            }
        }
    }
}
