@import 'styles/_colors.scss';
@import 'styles/_dimens.scss';
@import 'styles/_mixins.scss';
@import 'styles/_buttons.scss';

$background-color: $color-background-light;

.landing {
    align-items: center;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .landing-logo {
        margin-top: 12vh;
        margin-bottom: 8vh;
        background-color: $background-color;

        @include hover-darken-background($background-color);
    }

    .landing-card {
        width: 30rem;
    }

    .buttons-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;

        .link-button {
            @include dropshadow-standard();
            @include colored-in-button($color-text-primary);

            background-color: $color-text-primary;
            text-transform: capitalize;
            width: 64%;

            .label {
                color: inherit;
            }
        }

        .or-separator {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 3rem;
            margin-bottom: 3rem;
            width: 100%;

            .or {
                color: $color-text-secondary;
                font-weight: 500;
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }

        .separator-text {
            color: $color-text-primary;
            font-weight: bold;
            margin-left: 1rem;
            margin-right: 1rem;
            text-transform: capitalize;
        }
    }
}
