@import 'styles/_buttons.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';
@import 'styles/_dimens.scss';

.github-login-button {
    @include dropshadow-standard();
    @include colored-in-button($color-text-primary);

    background-color: $color-text-primary;

    .github-icon {
        fill: $color-light;
        margin-right: 1rem;
    }

    .label {
        color: inherit;
    }
}
