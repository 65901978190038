@import 'styles/_dimens.scss';
@import 'styles/_mixins.scss';
@import 'styles/_colors.scss';

.header {
    align-items: center;
    background-color: $color-background-white;
    display: flex;
    flex-direction: row;
    height: $header-height;
    justify-content: space-between;
    padding: 2rem 1rem;
    width: 100%;
    z-index: $header-z-index;

    @include dropshadow-standard();

    .left-container {
        width: min-content;
    }

    .right-container {
        width: min-content;
        white-space: nowrap;
    }
}
