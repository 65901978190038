@import "styles/_colors.scss";
@import "styles/_dimens.scss";
@import "styles/_mixins.scss";

.form-error {
    @include smooth-transition-all();

    align-items: center;
    background-color: $color-error;
    color: $color-light;
    display: flex;
    justify-content: center;
    max-height: 0;
    overflow: hidden;

    &.form-error--visible {
        min-height: $default-button-height;
        max-height: unset;
    }
}
