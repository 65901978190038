@import 'styles/_colors.scss';
@import 'styles/_dimens.scss';
@import 'styles/_mixins.scss';

.dropdown {
    align-items: center;
    background: $color-background-white;
    border-radius: $default-border-radius;
    display: flex;
    flex-direction: column;
    margin-top: 0.1rem;
    position: absolute;
    width: 100%;
    top: 105%;

    .dropdown-content {
        border-radius: $default-border-radius;
        box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        width: 100%;

        >:first-child {
            border-top-left-radius: $default-border-radius;
            border-top-right-radius: $default-border-radius;
            padding-top: 1rem;
        }

        >:last-child {
            border-bottom-left-radius: $default-border-radius;
            border-bottom-right-radius: $default-border-radius;
            padding-bottom: 1rem;
        }
    }
}
