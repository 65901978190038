$default-button-height: 2.5rem;

$default-border-radius: 0.6rem;
$default-transition-time: 0.15s;

$header-height: 4rem;

$smooth-transition: cubic-bezier(0.4, 0.0, 0.2, 1);

$blur-z-index: 2;
$sidebar-z-index: 3;
$header-z-index: 4;
$modal-z-index: 5;
