@import 'styles/_dimens.scss';
@import 'styles/_colors.scss';

@mixin smooth-transition-all() {
    transition: all $default-transition-time $smooth-transition;
}

@mixin header-font() {
    font-family: 'Nunito', sans-serif;
}

@mixin body-font() {
    font-family: 'Open Sans', sans-serif;
}

@mixin dropshadow-standard() {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

@mixin dropshadow-card() {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
}

@mixin hover-background($color, $hover-color) {
    @include smooth-transition-all();

    background-color: $color;

    &:hover, &:focus {
        background-color: $hover-color;
    }
}

@mixin hover-darken-background($color) {
    @include smooth-transition-all();

    &:hover, &:focus {
        background-color: darken($color, 4%);
    }
}

@mixin hover-darken-border($color) {
    @include smooth-transition-all();

    &:hover, &:focus {
        border-color: darken($color, 4%);
    }
}

@mixin hover-text($color, $hover-color) {
    @include smooth-transition-all();

    color: $color;

    &:hover, &:focus {
        color: $hover-color;
    }
}
