@import 'styles/_colors.scss';
@import 'styles/_dimens.scss';
@import 'styles/_mixins.scss';

.card {
    display: flex;
    background-color: $color-background-white;
    border-radius: $default-border-radius;
    flex-direction: column;

    @include dropshadow-card();

    .card-section {
        padding: 2rem;
        width: 100%;
        height: 100%;
    }
}
