@import 'styles/_colors.scss';
@import 'styles/_dimens.scss';
@import 'styles/_mixins.scss';
@import 'styles/_buttons.scss';

$background-color: $color-background-light;

.login {
    align-items: center;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .login-logo {
        margin-top: 12vh;
        margin-bottom: 8vh;
        background-color: $background-color;

        @include hover-darken-background($background-color);
    }

    .login-card {
        width: 30rem;

        .social-buttons-container {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;
            margin-bottom: 1rem;
            align-items: center;

            .github-button {
                height: 3rem;
            }
        }

        .or-separator {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 3rem;
            margin-bottom: 3rem;
            width: 100%;

            .or {
                color: $color-text-secondary;
                font-weight: 500;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        .form-input {
            width: 100%;
            margin-bottom: 2rem;
        }

        .buttons-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 1rem;
            margin-bottom: 1rem;

            .login-button {
                width: 48%;
                text-transform: capitalize;
            }

            .signup-button {
                background-color: $color-light;
                width: 48%;
                text-transform: capitalize;

                @include border-button($color-text-primary, $color-light);
            }
        }
    }
}
