@import "styles/_buttons.scss";
@import "styles/_colors.scss";
@import "styles/_mixins.scss";

.loading-button {
    @include dropshadow-standard();
    @include colored-in-button($color-text-primary);

    background-color: $color-text-primary;

    .label {
        color: inherit;
    }
}
