@import 'styles/_colors.scss';

$user-dropdown-spacing: 0.5rem;

.user-dropdown {
    .user-dropdown-content {
        padding: $user-dropdown-spacing;

        .user-dropdown-heading {
            color: $color-text-secondary;
            padding: $user-dropdown-spacing;
            width: 100%;
        }

        .user-dropdown-item {
            display: flex;
            align-items: center;
            padding: $user-dropdown-spacing;
            font-weight: bold;
            width: 100%;

            .item-icon {
                margin-right: $user-dropdown-spacing;
                fill: $color-text-primary;
            }
        }

        .user-dropdown-separator {
            margin: $user-dropdown-spacing;
            width: unset;
        }
    }
}
