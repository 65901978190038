@import "styles/_colors.scss";
@import "styles/_dimens.scss";

/* Inspired by https://codepen.io/jczimm/pen/vEBpoL */
.spinner {
    margin: 0 auto;
    position: relative;
    width: 1.5rem;

    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    .circle {
        animation: rotate 2s linear infinite;
        bottom: 0;
        height: 100%;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        transform-origin: center center;
        width: 100%;

        .path {
            animation: dash 1.5s ease-in-out infinite;
            stroke: $color-neutral-8;
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            stroke-linecap: round;
        }
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
