@import 'styles/_colors.scss';
@import 'styles/_dimens.scss';
@import 'styles/_fonts.scss';
@import 'styles/_mixins.scss';

h1, h2, h3, h4, h5, h6 {
    @include header-font();
    color: $color-text-primary;
}

div, a, p, span, button, input {
    @include body-font();
    color: $color-text-primary;
}

html, body, #root, #app {
    height: 100%;
    width: 100%;
}

#app {
    display: flex;
    flex-direction: column;
    background-color: $color-background-white;
}
